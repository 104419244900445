//
//
//
//
//
//
//
//
//
//
//
//
//

import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "TextareaInput",
  components: {},
  props:{
    value: {
      type: String,
      default: null
    },
    placeholder:{
      type: String,
      default: '请输入内容'
    },
    readerMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
